<template>
  <span>
    <v-data-table
      :loading="loading"
      :headers="computedHeaders"
      :disable-sort="true"
      :items="items"
      class="dataTable elevation-1"
      calculate-widths
      hide-default-footer
      disable-pagination
      fixed-header
      :height="computedHeight"
      :dense="true"
      mobile-breakpoint="0"
    >
      <template #item.add_cut="{ item }">
        <span
          :class="
            item.wallet_status !== 2 ? `color-red-text` : `color-green-text`
          "
        >
          <span v-if="item.wallet_status !== 2">
            {{ ("-" + item.added_wallet) | currency }}
          </span>
          <span v-else>
            {{ item.added_wallet | currency }}
          </span>
        </span>
      </template>
      <template #item.created_at="{ value }">
        {{ value | moment("DD-MM-YYYY h:mm a") }}
      </template>
      <template #item.wallet_amount="{ value }">
        {{ value | currency }}
      </template>
      <template #item.total_wallet_amount="{ value }">
        {{ value | currency }}
      </template>
      <template #item.wallet_description="{ value }">
        {{ handleOriginTranslate(value) }}
      </template>
    </v-data-table>
  </span>
</template>
<script>
import { mdiDotsVertical } from "@mdi/js";
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    page: {
      required: true,
    },
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
      },
    };
  },
  computed: {
    computedHeight() {
      if (this.items.length > 9) {
        return 580;
      }
      if (this.items.length > 7) {
        return 500;
      }
      if (this.items.length > 5) {
        return 500;
      }
      if (this.items.length > 3) {
        return 500;
      }
      if (this.items.length > 1) {
        return 500;
      }
    },
    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
  methods: {
    handleOriginTranslate(trip) {
      if (this.$vuetify.lang.current === "es") {
        trip = trip.replace("By Admin", "Por admin");
        trip = trip.replace("User amount limit", "Monto usuario");
        trip = trip.replace("Charge Of This Trip", "Cargo del viaje");
        trip = trip.replace("Card", "Tarjeta");
        trip = trip.replace("Charge Of This Parcel", "Cargo del envío");
        return trip;
      }
      return trip;
    },
  },
};
</script>
<style lang="scss">
.dataTable {
  tbody tr:nth-of-type(even) {
    background-color: rgba(58, 53, 65, 0.04);
  }

  tbody tr:hover {
    background-color: rgba(0, 5, 34, 0.08) !important;
  }

  tbody tr {
    border-bottom: hidden !important;
  }
}
</style>
