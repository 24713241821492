var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","height":_vm.computedHeight,"dense":true,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.add_cut",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.wallet_status !== 2 ? "color-red-text" : "color-green-text"},[(item.wallet_status !== 2)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(("-" + item.added_wallet)))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.added_wallet))+" ")])])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("moment")(value,"DD-MM-YYYY h:mm a"))+" ")]}},{key:"item.wallet_amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}},{key:"item.total_wallet_amount",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("currency")(value))+" ")]}},{key:"item.wallet_description",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.handleOriginTranslate(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }